<template>
  <div>
    <validation-observer
        ref="refFormObserver"
    >
      <validation-provider
          #default="validationContext"
          name="Full Name"
          rules="required"
      >
        <b-form-group label="Name" for="name">
          <b-form-input autofocus v-model="form.name"></b-form-input>
          <b-form-invalid-feedback>
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>

      <div class="w-100 d-flex justify-content-end mt-3">
        <b-button variant="primary" @click="handleSubmit">
          {{$t('message.Save')}}
        </b-button>
        <b-button variant="warning" class="ml-1" @click="$emit('closeModal')">
          {{$t('message.Cancel')}}
        </b-button>
      </div>

    </validation-observer>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, alphaNum } from '@validations'

export default {
  name: 'SourceModal',
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      required,
      alphaNum,
      form: {
        name: '',
        contacts: [
          { number: '' },
        ],
      },
      disabled: false,
    }
  },
  methods: {
    increaseContact() {
      
      this.form.contacts.push({ number: '' })
    },
    removeNumber(index) {
      this.disabled = true;
        setTimeout(() => {
          this.disabled = false;
        }, 1000)
      this.form.contacts.splice(index, 1)
    },
  },
}
</script>

<style scoped>

</style>
