<template>
  <div>
    <b-overlay :show="loading">
      <div class="w-100 d-flex justify-content-end">
        <b-button variant="primary" class="mb-1" @click="formModalActive = true">
          <b-icon icon="plus"></b-icon>{{$t('message.Add')}}
        </b-button>
      </div>
      <b-card>
        <b-table :fields="fields" :items="items">

          <template #cell(id)="data">
            <span class="text-md text-primary"> {{ (data.index + 1) }}</span>
          </template>
          <!-- <template #cell(action)>
          <div class="d-flex">
            <div>
              <b-button variant="outline-warning" size="sm">
                <b-icon icon="pencil"></b-icon>
              </b-button>
            </div>
            <div class="ml-1">
              <b-button variant="outline-danger" size="sm">
                <b-icon icon="trash"></b-icon>
              </b-button>
            </div>
          </div>
        </template> -->
        </b-table>
      </b-card>
    </b-overlay>

    <b-modal hide-footer v-model="formModalActive" no-close-on-backdrop>
      <SourceModal @closeModal="formModalActive = false;" />
    </b-modal>
  </div>
</template>

<script>
  import SourceModal from '@/views/directories/customer-source/components/SourceModal'

  export default {
    name: 'Index',
    components: {
      SourceModal
    },
    data() {
      return {
        fields: [{
            key: 'id',
            label: 'ID'
          },
          {
            key: 'name',
            label: 'Name'
          },
          // {
          //   key: 'action',
          //   label: 'Action'
          // },
        ],
        items: [{
            id: 1,
            name: 'Name1'
          },
          {
            id: 2,
            name: 'Name2'
          },
        ],
        formModalActive: false,
        loading: false,
      }
    },
  }
</script>